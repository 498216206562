* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.location {
  position: relative;
  background-image: url("../../assets/img/APRE/BannerAppNight_Wide2.jpg");
  background-size: cover; 
  background-position: center; 
  padding: 120px;
}

.location .ticket {
  position: absolute;
  height: auto;
  /* width: 10%; */
  text-align: center;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -30%);
}

@media (orientation: portrait) {
  .location {
    height: auto;
    padding: 90px;
  }
  .location-time h2 {
    font-size: 1.6em;
  }
  .card-location {
    margin-top: 20px;
    max-width: 100%;
  }
  .location .ticket {
    width: 51%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
}


@media (orientation: landscape) {
  .location {
    height: auto;
    padding: 145px;
  }
  .location .ticket {
    width: 46%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }

}
@media (min-width: 768px) and (max-width: 1023px) {
  .location {
    height: auto;
    padding: 20px 0;
    padding: 285px 0 60px;
  }
  .location .ticket {
    width: 47%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .location {
    position: relative;
    background-image: url("../../assets/img/APRE/BannerAppNight_Wide2.jpg");
    background-size: cover; 
    background-position: center; 
    padding: 197px;
  } 
}

@media (min-width: 1024px) {
  .location {
    height: auto;
    padding: 285px 0 60px;
  }
  .location .ticket {
    width: 25%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  .location {
    position: relative;
    background-image: url("../../assets/img/APRE/BannerAppNight_Wide2.jpg");
    background-size: cover; 
    background-position: center; 
    /* padding: 134px; */
  }
}

/* @media (min-width: 1440px) {
  .location {
    height: auto;
    padding: 285px 0 60px;
  }
  .location .ticket {
    width: 25%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
  
} */
/* Mobile devices */
@media (max-width: 767px) {
  .location {
    height: auto;
    padding: 90px;
  }
  .location .ticket {
    width: 50%;
    height: auto;
    top: -5px;
    left: 50%;
    transform: translate(-50%, -30%);
  }
}