body{
  padding: 0;
  margin: 0;
}
.about {
  background-size: cover;
  padding-top: 50px;
  background-color: #000;
  padding-bottom: 300px;
  color: #fff;
  padding-bottom: 170px;
}

.judul-about {
  position: relative;
}

.text-about {
  text-align: center;
  color: white;
  font-size: large;
  font-weight: bolder;
  position: relative;
}

.text-container {
  display: flex;
  align-items: center; 
  justify-content: center; 
  gap: 10px; 
  margin-top: 20px;
}

.quote {
  font-size: 4em; 
  margin: 0; 
  padding-right: 10px; 
}

.text-about {
  text-align: center;
  color: white;
  font-size: large;
  font-weight: bolder;
  position: relative;
}

.h1 {
  margin: 0;
  font-size: 5rem;
  color: #ffcc00;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 2em;
  text-align: justify; 
}
.list {
  list-style: none;
  padding: 0;
}

.list li {
  margin-bottom: 0.5em;
}

.list li::before {
  content: "►";
  margin-right: 10px;
  color: gold;
}
@media screen and (max-width: 768px) {
  body {
    padding-left: 10px;
  }
  .h1 {
    font-size: 3rem;
  }
}
