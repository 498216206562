body {
  background-color: #000000;
}

.img-fluid {
  width: 40%;
  height: auto;
} 

.img-banner {
  max-width: 70%;
  height: auto;
}

.form-register {
  position: relative;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  /* height: 700px; */
  background: #fff;
  width: 600px;
  border-radius: 50px;
  /* margin: 80px auto; */
  padding: 50px 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.form-register h2 {
  font-size: 1.8em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}
.form-register h1 {
  color: black;
  font-size: 1.8em;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.form-input {
  margin-bottom: 20px;
}

.form-input label {
  font-weight: 700;
}

.form-control,
.form-select {
  height: 45px;
}

.btn-register {
  width: 100%;
  height: 50px;
  border-radius: 20px;
  background-color: #000000;
  font-weight: 700;
  color: #001345;
}

input.form-control.input-invalids {
  /* border-color: #dc3545; */
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.text-invalids {
  color: #dc3545;
  font-size: 12.25px;
}

@media (width < 769px) {
  .form-register {
    width: 90%;
    padding: 50px 25px;
  }
  .form-register h2 {
    font-size: 1.2em;
  }
  .form-register h1 {
    font-size: 1.2em;
  }
  .form-input label {
    font-size: 0.9em;
  }
  .img-banner {
    max-width: 90%;
    height: auto;
  }
}
@media (max-width: 768px) {
    
    .img-fluid {
      width: 60%; 
    }
  
    .img-banner {
      max-width: 60%; 
    }
  }